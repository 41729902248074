.parent {
  height: 100vh;
  width: 100vw;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  margin-top: 0px !important;
  z-index: -1;

  /* Fade */
  animation: fadein 2s;
}

.parent img {
  width: 90%;
  display: block;
  animation: zoom 2s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes zoom {
  0% {
    transform: scale(0.95);
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
