.memberCard {
  background-color: rgb(255, 255, 255);
  height: 600px;
  width: 370px;
  overflow: hidden;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  background-position: center;
  transition: 1s;
  margin-top: 25px;
}

.info {
  align-self: flex-end;
  height: 170px;
  width: 355px;
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  background: rgba(255, 255, 255, 0.6);
  background: linear-gradient();
  border-radius: 20px;
  margin-bottom: 8px;
  transition: all ease 0.5s;
}

.text {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

li {
  list-style-type: none;
  margin: 0;
}

.name {
  font-size: 150%;
  color: #424242;
}

.desc {
  color: #424242;
  margin-top: 0;
  text-align: left;
  width: 100%;
}

.mail {
  color: rgba(255, 255, 255, 0);
  margin-top: 0;
  text-align: left;
  width: 100%;
  font-size: 95%;
  transition: 0.5s;
  position: relative;
  top: -13px;
}

.memberCard:hover .info {
  height: 180px;
}

.memberCard:hover .mail {
  color: #424242;
}

.airImage {
  width: 10%;
  display: block;
  margin-left: 0;
  margin-top: 35px;
  margin-bottom: 15px;
}

/* .memberCard:hover {
  transform: scale(1.05);
  box-shadow: rgba(255, 177, 177, 0.25) 0px 40px 40px,
    rgba(250, 154, 154, 0.12) 0px -12px 30px,
    rgba(255, 157, 157, 0.12) 0px 4px 6px,
    rgba(255, 128, 128, 0.17) 0px 12px 13px,
    rgba(255, 106, 106, 0.09) 0px -3px 5px;
} */

.memberCard:hover {
  transform: translateY(-25 px);
  animation-name: hover;
  animation-duration: 1.5s;
  animation-delay: 0.2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  box-shadow: #727272 0px 15px 25px, rgba(250, 154, 154, 0.12) 0px -12px 30px,
    #4d4d4d 0px 4px 6px, rgba(255, 128, 128, 0.17) 0px 12px 13px,
    #707070 0px -3px 5px;
}

@keyframes hover {
  50% {
    transform: translateY(-3px);
  }

  100% {
    transform: translateY(-6px);
  }
}

@media screen and (min-width: 1600px) {
  .mail {
    font-size: 101%;
    transform: translateY(-5px);
  }
}
