.parent {
  height: 95vh;
  width: 98vw;
  background: rgb(184, 184, 184);
  background: linear-gradient(90deg, #f5f3f3, #a2a2a2);
  margin-left: auto;
  margin-right: auto;
}

.content {
  display: flex;
  justify-content: center;
  height: 100%;
}

.left {
  margin-left: 85px;
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 48%;
  align-items: flex-start;
}

.head1 {
  font-size: 3rem;
  margin: 0;
  color: #000000;
  font-family: titleS;
  font-weight: lighter;
}

.para1 {
  font-size: 0.99rem;
  text-align: left;
  width: 97%;
  line-height: 25px;
  margin: 0;
  color: #707070;
  margin-bottom: 20px;
}

.icons {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 65%;
  transform: translateY(10px);
}

.image {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 200%;
}

@media screen and (min-width: 1600px) {
  .para1 {
    font-size: 1.3rem;
    line-height: 2.2rem;
  }
}

.dev {
  width: 80%;
  display: block;
}

@media screen and (max-width: 846px) {
  .content {
    height: 100%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .image {
    display: none;
  }

  .left {
    height: 100%;
    align-items: center;
    justify-content: space-evenly;
    margin: 0;
  }

  .head1 {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .para1 {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 85%;
  }

  .icons {
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    justify-items: center;
  }
}

@media screen and (max-width: 640px) {
  .icon {
    width: 100px;
  }

  .head1 {
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 640px) {
  .icon {
    width: 100px;
  }

  .head1 {
    font-size: 2.5rem;
  }
}
