.parent1 {
  position: fixed;
  top: 0;
  margin-top: 15px;
  left: 50%;
  transform: translate(-50%, 0);
  height: 5vh;
  width: 85vw;
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  background-color: rgba(226, 226, 226, 0.5);
  border-radius: 10px;
  z-index: 9999;
  /* yes */

  display: flex;
  justify-content: space-around;
  align-items: center;

  /* Fade */
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 30%;
  transform: translateX(-1%);
}

.textNav {
  margin: 0;
  width: fit-content;
  font-size: 0.8rem;
  color: black;
}

.icon {
  display: block;
  height: fit-content;
  width: 4.5%;
}

.b1 {
  z-index: 9999;
  display: none;
}

@media screen and (max-width: 1330px) {
  .parent1 {
    height: 7vh;
  }
  .flex {
    width: 35%;
  }
}

@media screen and (max-width: 1010px) {
  .parent1 {
    height: 8vh;
  }
  .flex {
    width: 50%;
  }
}

@media screen and (max-width: 803px) {
  .textNav {
    font-size: 1rem;
  }
  .textNav {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 707px) {
  .flex {
    width: 55%;
  }
}

@media screen and (max-width: 680px) {
  .flex {
    width: 65%;
  }
}

@media screen and (max-width: 585px) {
  .flex {
    width: 75%;
  }
}

@media screen and (max-width: 465px) {
  .flex {
    width: 87%;
  }
  .textNav {
    font-size: 0.7rem;
  }
}

@media screen and (max-width: 400px) {
  .textNav {
    display: none;
  }

  .icon {
    display: none;
  }

  .parent1 {
    width: 60px;
    height: 60px;
    justify-content: center;
    align-items: center;
  }

  .flex {
    justify-content: center;
    align-items: center;
  }

  .b1 {
    border: none;
    background: none;
    cursor: pointer;
    display: block;
    margin-right: 12px;
    margin-top: 5px;
  }

  .airBtn {
    width: 35px;
    display: block;
  }
}

@keyframes fadein {
  from {
    opacity: 0.3;
  }
  to {
    opacity: 1;
  }
}

@media screen and (min-width: 1600px) {
  .icon {
    width: 4%;
  }
}
