.parent {
  min-height: fit-content;
  height: 75vh;
  width: 98vw;
  background: rgb(128, 173, 241);
  background: linear-gradient(#329fff 0%, #68bbfa 100%);
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex {
  display: flex;
  flex-direction: column;
}

.flex p {
  font-size: 1rem;
  transform: translateY(-35%);
  line-height: 25px;
  transform: translateY(1%);
}

.flex img {
  width: 22%;
  min-width: 169px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 1120px) {
  .flex p {
    font-size: 1rem;
    margin-top: 4rem;
  }
}

@media screen and (max-width: 1050px) {
  .flex p {
    font-size: 0.8rem;
    margin-top: 4rem;
  }
}

@media screen and (max-width: 858px) {
  .flex p {
    font-size: 0.8rem;
    margin-top: 4rem;
  }

  .flex img {
    width: 22%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-top: 2.5rem;
  }
}

@media screen and (max-width: 714px) {
  .flex p {
    width: 80%;
  }
}

@media screen and (max-width: 520px) {
  .flex img {
    padding-top: 5rem;
  }
}
/* 
@media screen and (max-width: 639px) {
  .flex img {
    min-width: 20%;
  }
}

@media screen and (max-width: 582px) {
  .flex p {
    font-size: 0.8rem;
  }
} */

@media screen and (min-width: 1600px) {
  .flex p {
    font-size: 1.2rem;
    line-height: 2rem;
  }

  .flex img {
    width: 19%;
  }
}
