* {
  margin: 0;
  padding: 0;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255);
  width: 95px;
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 50px #234f81;
  border-radius: 8px;
}

@font-face {
  font-family: titleS;
  src: url("./assets/Segoe UI.ttf");
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
