.parent {
  height: 95vh;
  width: 98vw;
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  background-color: rgba(226, 226, 226, 0.5);
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.flex {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.flex h1 {
  font-size: 3.5rem;
  font-family: titleS;
  font-weight: lighter;
  color: #000000;
  transform: translateY(-5px);
}

.flex p {
  font-size: 1.1rem;
  color: #707070;
}

@media screen and (max-width: 475px) {
  .flex h1 {
    font-size: 3rem;
  }
  .flex p {
    font-size: 1rem;
  }
}

@media screen and (min-width: 1600px) {
  .flex p {
    font-size: 130%;
    color: #707070;
    line-height: 2rem;
  }
}
