/* #D2B447 #B99E3C #AE9436 */
.parent {
  height: 50vh;
  width: 98vw;
  margin-left: auto;
  margin-right: auto;
}

.wrap {
  height: 100%;
  display: grid;
  grid-template-columns: 2fr 2.5fr 4.5fr;
}

.logo {
  height: 100%;
  background: #3d73b1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logoimg {
  padding: 0;
}

.socials {
  height: 100%;
  background: #396da8;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.flexSocials {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 55%;
}

.socials p {
  width: 75%;
}

.icons {
  margin: auto;
  padding: 0;
  display: block;
  width: 55%;
  display: block;
}

.links {
  height: 100%;
  background: #3669a4;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-content: space-evenly;
  justify-items: center;
  color: white;
}

.titlelist {
  font-size: 150%;
  font-weight: lighter;
  margin-bottom: 20px;
}

li {
  padding-bottom: 10px;
  font-size: 80%;
}

@media screen and (min-width: 1600px) {
  .list {
    font-size: 120%;
  }

  .titlelist {
    font-size: 190%;
  }

  .socials p {
    width: 75%;
    font-size: 140%;
  }
}

@media screen and (max-width: 763px) {
  .wrap {
    grid-template-columns: 1fr 2fr;
  }

  .logo {
    display: none;
  }
}

@media screen and (max-width: 569px) {
  .wrap {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 3fr;
  }

  .logo {
    display: none;
  }

  .socials {
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: center;
    justify-items: center;
  }

  .socials p {
    width: 130%;
    height: 100%;
    margin-left: 15px;
  }

  .icons {
    margin-right: 20px;
  }
}

@media screen and (max-width: 568px) {
  .links {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    margin-bottom: 45px;
    padding-top: 15px;
  }

  .contact {
    display: none;
  }

  .flexSocials {
    display: grid;
    height: 150px;
    width: 150%;
    transform: translateX(60%);
  }

  .flexSocials p {
    width: fit-content;
    height: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    padding-top: 0;
  }

  .icons {
    margin-top: 5px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
  }
}
