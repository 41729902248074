.parent {
  width: 98vw;
  background: #282828;
  margin-left: auto;
  margin-right: auto;
}

.airTeam {
  width: 15%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 7rem;
}

p {
  margin-top: 3rem;
  color: white;
  text-align: center;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  font-size: 1rem;
  line-height: 25px;
}

.members {
  margin-top: 5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding-bottom: 4rem;
  width: 90vw;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 708px) {
  .airTeam {
    width: 30%;
  }
}

@media screen and (min-width: 1600px) {
  p {
    font-size: 1.3rem;
    line-height: 2rem;
  }
}
